// roundimage_text max 52rem

.workflow {

  .workflow_container {

    .workflow_item {

      h3, p {
        margin-left: -2rem;
        margin-right: -2rem;
      }
    }
  }
}